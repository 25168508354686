<template>
  <div class="login">
    <van-cell-group>
      <van-field
        v-model="loginForm.username"
        label="用户名"
        placeholder="请输入用户名"
      />
      <van-field
        v-model="loginForm.psd"
        type="password"
        label="密码"
        placeholder="请输入密码"
      />
    </van-cell-group>
    <div class="btn">
      <van-button @click="loginBtn" style="width: 100%" type="primary">登录</van-button>
    </div>
  </div>
</template>

<script>
import { Login } from "@/api/com";
import { Toast } from "vant";
export default {
  data() {
    return {
      loginForm: {
        username: "",
        psd: "",
      },
    };
  },
  methods: {
    async loginBtn() {
        console.log('===');
      if (this.loginForm.username == "" || this.loginForm.psd == "") {
        Toast.fail("请输入用户名和密码");
        return;
      }
      let res = await Login(this.loginForm);
      console.log(res);
       localStorage.setItem('token', res.token);
       this.$router.push('/')
    },
  },
};
</script>

<style scoped>
.login {
  padding: 20px;
}
.btn {
  text-align: center;
  padding-top: 20px;
}
</style>